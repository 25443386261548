import React, {useContext, useState} from 'react'

import TourDate from './tour-date'

import styles from './tour.module.css'
import {responsiveTitle2} from '../typography.module.css'
import {cn} from '../../lib/helpers'
import {AppContext} from '../../contexts/app-context'

const Tour = ({ tourTitle = '', src = '', alt = '', tourDates = [], ticketsButtonText, isUpcoming }) => {
  const [showDates, setShowDates] = useState(isUpcoming)
  const handleClick = () => setShowDates(!showDates)
  const {showGlow} = useContext(AppContext)
  const titleClassName = showGlow ? cn(responsiveTitle2, styles.title, 'glow') : cn(responsiveTitle2, styles.title)
  const imgClassName = showGlow ? cn(styles.image, 'aura') : styles.image

  return (
    <li className={styles.wrapper}>
      <h2 className={titleClassName} onClick={handleClick}>
        {tourTitle}
      </h2>
      {
        !!showDates && (!!src && !!alt) &&
          <img className={imgClassName} src={src} alt={alt} />
      }
      {
        !!showDates &&
          <ul className={styles.ul}>
            {
              tourDates.map(({tourDate, location, venueName, ticketsUrl}, i) =>
                <TourDate
                  key={i}
                  tourDate={tourDate}
                  location={location}
                  venueName={venueName}
                  ticketsUrl={ticketsUrl}
                  ticketsButtonText={ticketsButtonText}
                />
              )
            }
          </ul>
      }
    </li>
  )
}

export default Tour