import {format} from 'date-fns'
import React, {useContext} from 'react'

import Button from '../../button'

import styles from './tour-date.module.css'
import {paragraph, responsiveTitle3} from '../../typography.module.css'
import {cn} from '../../../lib/helpers'
import {AppContext} from '../../../contexts/app-context'

const TourDate = ({
  tourDate = '',
  location = '',
  venueName = '',
  ticketsUrl = '',
  ticketsButtonText = ''
}) => {
  const {showGlow} = useContext(AppContext)
  const dateClassName = showGlow ? cn(responsiveTitle3, 'glow') : responsiveTitle3
  return (
    <li className={styles.wrapper}>
      <h3 className={dateClassName}>
        {format(tourDate, 'DD/MM/YYYY')}
      </h3>
      <div className={paragraph}>
        <p className={cn('ellipsis', styles.venue)} title={venueName}>
          {venueName}
        </p>
        <p className='ellipsis' title={location}>
          {location}
        </p>
      </div>
      {
        ticketsUrl &&
          <Button
            text={ticketsButtonText || 'Tickets'}
            url={ticketsUrl}
          />
      }
    </li>
  )
}

export default TourDate
