import {graphql} from 'gatsby'
import React from 'react'

import Tour from '../components/tour'
import Layout from '../containers/layout'

import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import styles from './tours.module.css'

export const query = graphql`
  query ToursPageQuery {
    tours: allSanityTour(
      sort: {fields: [createdAt], order: DESC}
    ) {
      edges {
        node {
          id
          tourTitle
          isUpcoming
          createdAt
          image {
            asset {
              _id
            }
            alt
          }
          ticketsButtonText
          tourDates {
            tourDate
            location
            venueName
            ticketsUrl
          }
        }
      }
    }
  }
`

const ToursPage = ({ data: { tours } }) => {
  const upcomingTours = tours.edges.filter(({node: {isUpcoming}}) => isUpcoming)
  const pastTours = tours.edges.filter(({node: {isUpcoming}}) => !isUpcoming)
  return (
    <Layout>
      <ul>
        {
          !!upcomingTours.length &&
            upcomingTours.map(({node: {id, tourTitle, image, ticketsButtonText, tourDates}}) => {
              const src = image ? imageUrlFor(buildImageObj(image)).url() : ''
              const alt = image ? image.alt : ''
              return (
                <Tour
                  key={id}
                  src={src}
                  alt={alt}
                  tourTitle={tourTitle}
                  tourDates={tourDates}
                  ticketsButtonText={ticketsButtonText}
                  isUpcoming={true}
                />
              )
            })
        }
      </ul>
      <div className={styles.divider}></div>
      <ul>
        {
          !!pastTours.length &&
            pastTours.map(({node: {id, tourTitle, image, ticketsButtonText, tourDates}}) => {
              const src = image ? imageUrlFor(buildImageObj(image)).url() : ''
              const alt = image ? image.alt : ''
              return (
                <Tour
                  key={id}
                  src={src}
                  alt={alt}
                  tourTitle={tourTitle}
                  tourDates={tourDates}
                  ticketsButtonText={ticketsButtonText}
                />
              )
            })
        }
      </ul>
    </Layout>
  )
}

export default ToursPage
